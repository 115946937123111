.wrapper {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  background: rgba(255, 255, 255, 0.4);
  width: 100%;
  height: 100%;
  color: #000000;
}

.icon {
  font-size: 3rem;
}
