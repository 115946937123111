.tab {
  margin-right: 2px;
  outline: none;
  border: solid 1px #cdcdcd;
  background-color: #fafafa;
  padding: 10px;
}

.tabSelected {
  background-color: #fff;
  font-weight: bold;
}
