@value third-font from "@/styles/variables.module.css";

.datePickerButton {
  border: 1px solid #d9d9d9;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.csvButton {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  cursor: pointer;
  border: 1.5px solid #000;
  padding: 15px;
  width: 200px;
  height: 50px;
  font-weight: 700;
  font-size: 12px;
  font-family: third-font;
  text-transform: uppercase;
}

.isDisabled {
  opacity: 0.5;
  cursor: not-allowed;
  border-color: gray;
  color: gray;
  text-decoration: none;
}

.selectsWrapper {
  display: flex;
  gap: 1rem;
  margin-bottom: 3rem;
}

.selectWrapper {
  flex: 1;
}
